<script>
// import { trackSilentException } from '../bugs'
import { DEFAULT_ROOM, ROOM_PATH, ROOM_URL } from '../config'
import { generateName } from '../lib/names'

export default {
    name: 'AppWhitelabel',
    components: {},
    data() {
        const defaultName = DEFAULT_ROOM ?? generateName()
        return {
            defaultName,
            room: defaultName,
            url: '',
            initialWidth: -1,
            currentChar: 0,
            observer: null,
            roomHtml: ROOM_URL.replace(/^https?:\/\//gm, '')
                .replaceAll('/', '<span class="slash">/</span>')
                .replaceAll('.', '<span class="dot">.</span>'),
        }
    },
    watch: {
        room() {
            this.updateInput()
        },
    },
    computed: {
        currentPath() {
            return window.location.pathname;
        }
    },
    async mounted() {
        await this.$nextTick()

        const input = this.$refs.input
        if (input) {
            input.style.width = `${input.scrollWidth}px`
            this.updateInput()

            this.observer = new ResizeObserver(this.updateInput)
            this.observer.observe(document.body)

            this.charAnimation()

            if (
                !/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)
            )
                input.focus()
        }
    },

    beforeUnmount() {
        this.observer?.disconnect()
    },
    methods: {
        doEnterRoom() {
            const room = this.room || this.defaultName || ''
            this.state.room = room
            try {
                window.history.pushState(
                    null, // { room },
                    null, // room,
                    ROOM_PATH + room,
                )
            }
            catch (err) {
                // trackSilentException(err)
            }
        },
        updateInput() {
            const input = this.$refs.input
            if (input) {
                if (this.initialWidth < 0)
                    this.initialWidth = input.scrollWidth
                const value = input.value.trim()
                input.style.width = '1px'
                input.style.width = `${value ? input.scrollWidth : this.initialWidth}px`
                this.url = ROOM_PATH + (value || this.defaultName)
            }
        },
        charAnimation() {
            setTimeout(() => {
                const input = this.$refs.input
                if (input) {
                    this.currentChar++
                    this.$refs.input.value = this.defaultName.substr(0, this.currentChar)
                    this.updateInput()
                    if (this.currentChar < this.defaultName.length)
                        this.charAnimation()
                }
            }, 100)
        },
    },
}
</script>

<template>
    <div class="-scroll">
        <!-- 222 -->
        <div class="page1">

            <header
                class="uk-width-1-1 uk-background-muted"
                uk-sticky="start: 0; animation: uk-animation-slide-top"
            >

                <div class="uk-container">
                    <div
                        class="uk-navbar-container"
                        uk-navbar="mode: click"
                    >

                        <div class="uk-navbar-left">
                            <a
                                class="uk-navbar-item uk-logo ag_main_logo"
                                href="https://allgram.best/"
                            >
                                <img
                                    :src="'/images/logos/logo.svg'"
                                    alt="Logo"
                                    class="a_logo"
                                >
                                <span class="ag_beta">beta</span>
                            </a>
                        </div>

                        <nav class="uk-navbar-center uk-visible@m">
                            <ul class="uk-navbar-nav uk-nav">

                                <li>
                                    <a
                                        href="https://allgram.best/download"
                                        class="uk-text-capitalize"
                                    ><span class="a_badge">Download</span></a>
                                </li>
                                <li>
                                    <a
                                        href='https://allgram.best/features'
                                        class="uk-text-capitalize"
                                    >Features</a>
                                </li>
                                <li>
                                    <a
                                        href='https://allgram.best/pricing'
                                        class= "uk-text-capitalize"
                                    >Pricing</a>
                                </li>
                                <li>
                                    <a
                                        href='https://faq.allgram.best/'
                                        class="uk-text-capitalize"
                                    >Help center</a>
                                </li>
                                <li class="uk-parent">

                                    <a
                                        href="#"
                                        class="uk-text-capitalize uk-text-emphasis"
                                    >
                                        Other products <span uk-nav-parent-icon></span>
                                    </a>

                                    <div
                                        class="uk-navbar-dropdown uk-border-rounded uk-padding-small"
                                        uk-dropdown="mode: click; offset: -15; pos: bottom-left;"
                                    >
                                        <ul class="uk-nav uk-padding-remove">

                                            <li>
                                                <a
                                                    class="uk-text-emphasis"
                                                    href="https://meet.allgram.best/"
                                                    target="_blank"
                                                >allgram Meet</a>
                                            </li>

                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href="https://files.allgram.best/"
                                                    target="_blank"
                                                >allgram Files</a>
                                            </li>

                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href="https://wallet.allgram.best/"
                                                    target="_blank"
                                                >allgram Wallet</a>
                                            </li>

                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a
                                        href="https://allgram.best/blog"
                                        class="uk-text-capitalize"
                                    >Blog</a>
                                </li>
                                <li class="uk-parent">

                                    <a
                                        href="#"
                                        class="uk-text-capitalize"
                                    >
                                    Info <span uk-nav-parent-icon></span>
                                    </a>
                                    <div
                                        class="uk-navbar-dropdown uk-border-rounded uk-padding-small"
                                        uk-dropdown="mode: click; offset: -15; pos: bottom-left;"
                                    >
                                        <ul class="uk-nav uk-padding-remove">
                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href='https://allgram.best/community-guidelines'
                                                >Community guidelines</a>
                                            </li>
                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href='https://allgram.best/terms-and-conditions'
                                                >Terms & Conditions</a>
                                            </li>
                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href='https://allgram.best/privacy-policy'
                                                >Privacy policy</a>
                                            </li>
                                            <li>
                                                <a
                                                    class="uk-text-muted"
                                                    href='https://allgram.best/gdpr'
                                                >GDPR</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <div class="uk-navbar-right">

                            <!-- mobile menu button -->
                            <a
                                href="#offcanvas"
                                class="uk-navbar-toggle uk-navbar-toggle-animate uk-hidden@m"
                                uk-toggle="target: #offcanvas"
                            >
                                <span
                                    class="uk-navbar-toggle uk-navbar-toggle-animate"
                                    uk-navbar-toggle-icon
                                ></span>
                                <span class="uk-margin-small-left">Menu</span>
                            </a>

                            <!-- hidden mobile menu -->
                            <div
                                id="offcanvas"
                                uk-offcanvas="mode: slide; overlay: true"
                            >
                                <div class="uk-offcanvas-bar">
                                    <button
                                        class="uk-offcanvas-close uk-close-large"
                                        type="button"
                                        uk-close
                                    ></button>
                                    <ul
                                        class="uk-nav uk-nav-primary uk-nav-left uk-margin-large-top uk-margin-left uk-margin-right"
                                        uk-nav
                                    >
                                        <li class="uk-text-default">
                                            <a
                                                href=''
                                                class= "uk-text-emphasis uk-text-capitalize nav-link uk-text-warning"
                                            >
                                                Download
                                            </a>
                                        </li>
                                        <li class="uk-text-default">
                                            <a
                                                href=''
                                                class="uk-text-capitalize nav-link"
                                            >
                                                Features
                                            </a>
                                        </li>
                                        <li class="uk-text-default">
                                            <a
                                                href=''
                                                class="uk-text-capitalize nav-link"
                                            >
                                                Pricing
                                            </a>
                                        </li>
                                        <li class="uk-text-default">
                                            <a
                                                href="https://faq.allgram.best/"
                                                class="uk-text-capitalize nav-link"
                                            >Help center</a>
                                        </li>
                                        <li class="uk-parent uk-text-default">

                                            <a
                                                href="#"
                                                class="uk-text-capitalize nav-link uk-text-muted"
                                            > Other products <span uk-nav-parent-icon></span></a>

                                            <ul class="uk-nav-sub">

                                                <li class="uk-text-default">
                                                    <a
                                                        class="uk-text-emphasis"
                                                        href="https://meet.allgram.best/"
                                                        target="_blank"
                                                    >allgram Meet</a>
                                                </li>

                                                <li class="uk-text-default">
                                                    <a
                                                        class="uk-text-muted"
                                                        href="https://files.allgram.best/"
                                                        target="_blank"
                                                    >allgram Files</a>
                                                </li>

                                                <li class="uk-text-default">
                                                    <a
                                                        class="uk-text-muted"
                                                        href="https://wallet.allgram.best/"
                                                        target="_blank"
                                                    >allgram Wallet</a>
                                                </li>

                                            </ul>

                                        </li>
                                        <li class="uk-text-default">
                                            <a
                                                href=''
                                                class="uk-text-capitalize nav-link"
                                            >
                                                Blog
                                            </a>
                                        </li>

                                        <li class="uk-parent uk-text-default">
                                            <a
                                                href="#"
                                                class="uk-text-capitalize nav-link"
                                            >
                                                Info <span uk-nav-parent-icon></span>
                                            </a>
                                            <ul class="uk-nav-sub">
                                                <li class="uk-text-default">
                                                    <a
                                                        href=''
                                                        class= "uk-text-emphasis"
                                                    >
                                                        Community guidelines
                                                    </a>
                                                </li>
                                                <li class="uk-text-default">
                                                    <a
                                                        href=''
                                                        class="uk-text-emphasis"
                                                    >
                                                        Terms & Conditions
                                                    </a>
                                                </li>
                                                <li class="uk-text-default">
                                                    <a
                                                        href=''
                                                        class="uk-text-emphasis"
                                                    >
                                                        Privacy policy
                                                    </a>
                                                </li>
                                                <li class="uk-text-default">
                                                    <a
                                                        href=''
                                                        class="uk-text-emphasis"
                                                    >
                                                        GDPR
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="uk-nav-divider uk-margin-small"></li>
                                        <li class="uk-parent uk-text-default">
                                            <a
                                                href="#"
                                                class="uk-text-capitalize nav-link"
                                            >
                                                Account <span uk-nav-parent-icon></span>
                                            </a>
                                            <ul class="uk-nav-sub">
                                                <li class="uk-text-default">
                                                    <a href=''>
                                                        Register
                                                    </a>
                                                </li>
                                                <li class="uk-text-default">
                                                    <a href=''>
                                                        Login
                                                    </a>
                                                </li>
                                                <li class="uk-nav-divider uk-margin-small"></li>
                                                <li class="uk-text-default">
                                                    <a href="https://test-web.allgram.best">
                                                        Web-client
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="uk-nav-divider uk-margin-small"></li>
                                        <li class="uk-text-default">
                                            <a
                                                href="#modalMenu"
                                                class="uk-text-capitalize nav-link"
                                                uk-toggle
                                            >
                                                Lang
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Cookie modal -->
                <div id="cookie-modal" class="uk-flex-top" uk-modal bg-close="false">
                    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-1-1">
                        <div class="uk-container uk-flex uk-flex-between uk-flex-middle">
                            <button class="uk-modal-close-default" type="button" uk-close></button>
                            <p class="uk-margin-remove">
                                We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are ok with it.
                            </p>
                            <button class="uk-button uk-button-primary">OK</button>
                        </div>
                    </div>
                </div>

            </header>

            <div class="uk-section uk-background-default uk-width-1-1">
                <div class="uk-container uk-text-large uk-text-center uk-margin-top uk-margin-bottom" uk-margin>
                    <form
                        class="uk-heading-small"
                        id="form"
                        @submit.prevent="doEnterRoom"
                    >
                        <a
                            id="link"
                            :href="url"
                            class="uk-width-1-1"
                            @click.prevent="doEnterRoom"
                            v-html="roomHtml"
                        />
                        <wbr>
                        <input
                            class="uk-width-1-1"
                            id="room"
                            ref="input"
                            v-model="room"
                            type="text"
                            name="room"
                            enterkeyhint="go"
                            spellcheck="false"
                            :placeholder="defaultName"
                        >
                    </form>
                    <div class="">
                        <a
                            id="button"
                            :href="url"
                            class="uk-button uk-button-primary uk-border-rounded uk-button-large uk-text-bold"
                            @click.prevent="doEnterRoom"
                        >{{ l.welcome.start }}</a>
                    </div>
                </div>
            </div>

            <footer class="uk-section uk-section-secondary uk-padding-remove-bottom">
                <div class="uk-container">
                    <div class="uk-grid uk-grid-large" uk-grid>
                        <div class="uk-width-1-3@m">
                            <a
                            class="uk-logo"
                            href="#"
                            >
                                <img
                                    :src="'/images/logos/logo.svg'"
                                    alt="Logo"
                                    class="a_logo"
                                >
                            </a>
                            <div class="uk-margin">
                                Introducing allgram, the revolutionary new messenger app that utilizes the power of P2P and blockchain technologies. With allgram, you can communicate with friends and family securely and privately without worrying about centralized servers and third-party access. Our advanced encryption ensures your messages and calls are completely secure, while our blockchain technology keeps your sensitive data safe. Say goodbye to data breaches and privacy concerns with allgram.
                            </div>
                            <div class="uk-margin">
                                Contact us
                            </div>
                            <div>
                                <a
                                    href="mailto:info@allgram.com"
                                    class="uk-icon-button uk-link-reset"
                                ><i class="las la-at"></i></a>
                                <a
                                    href="https://twitter.com/allgram"
                                    class="uk-icon-button uk-link-reset uk-margin-small-left"
                                    target="_blank"
                                ><i class="lab la-twitter"></i></a>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100082886108584"
                                    class="uk-icon-button uk-link-reset uk-margin-small-left"
                                    target="_blank"
                                ><i class="lab la-facebook-f"></i></a>
                                <a
                                    href="https://instagram.com/allgramim?igshid=YmMyMTA2M2Y="
                                    class="uk-icon-button uk-link-reset uk-margin-small-left"
                                    target="_blank"
                                ><i class="lab la-instagram"></i></a>
                                <a
                                    href="https://www.linkedin.com/in/allgram-app-4312b11b3/"
                                    class="uk-icon-button uk-link-reset uk-margin-small-left"
                                    target="_blank"
                                ><i class="lab la-linkedin-in"></i></a>
                                <a
                                    href="https://www.youtube.com/@allgram8577"
                                    class="uk-icon-button uk-link-reset uk-margin-small-left"
                                    target="_blank"
                                ><i class="lab la-youtube"></i></a>
                            </div>
                        </div>
                        <div class="uk-width-1-6@m">
                            <h5>
                                Our products
                            </h5>
                            <ul class="uk-list">
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://meet.allgram.best/"
                                        target="_blank"
                                    >Meet</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://files.allgram.best/"
                                        target="_blank"
                                    >Files</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://wallet.allgram.best/"
                                    target="_blank"
                                    >Wallet</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://web.allgram.best/"
                                    >
                                    Web-client
                                </a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://allgram.best/download-options"
                                        target="_blank"
                                    >For Windows</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://allgram.best/download-options"
                                        target="_blank"
                                    >For macOS</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://allgram.best/download-options"
                                        target="_blank"
                                    >For Linux</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://allgram.best/download-options"
                                        target="_blank"
                                    >For Android</a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://allgram.best/download-options"
                                        target="_blank"
                                    >For iOS</a>
                                </li>
                            </ul>
                        </div>
                        <div class="uk-width-1-6@m">
                            <h5>
                                Discover
                            </h5>
                            <ul class="uk-list">
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/features">
                                        Features
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/pricing">
                                        Pricing
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/blog">
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://faq.allgram.best/">
                                        Help center
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="uk-link-muted"
                                        href="https://stats.uptimerobot.com/2Q5MAiV4EK"
                                    >
                                        Ecosystem monitoring
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="uk-width-1-6@m">
                            <h5>
                                For users
                            </h5>
                        <ul class="uk-list">
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/login"
                                >
                                    Login
                                </a>
                            </li>
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/register"
                                >
                                    Create new account
                                </a>
                            </li>
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/reserved-name"
                                >
                                    Reserved name
                                </a>
                            </li>
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/public-api"
                                >
                                    Public API
                                </a>
                            </li>
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/for-creators"
                                >
                                    For creators
                                </a>
                            </li>
                            <li>
                                <a
                                    class="uk-link-muted"
                                    href="https://allgram.best/monetization"
                                >
                                    Monetization
                                </a>
                            </li>
                        </ul>
                        </div>
                        <div class="uk-width-1-6@m">
                            <h5>
                                Info
                            </h5>
                            <ul class="uk-list">
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/community-guidelines">
                                        Community guidelines
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/terms-and-conditions">
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/privacy-policy">
                                        Privacy policy
                                    </a>
                                </li>
                                <li>
                                    <a class="uk-link-muted" href="https://allgram.best/gdpr">
                                        GDPR
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr class="uk-margin-medium">

                <div class="uk-text-center uk-margin-medium-bottom">
                    <span class="uk-text-small uk-text-muted">
                        &copy; 2023 Allgram LLC
                    </span>
                </div>

            </footer>
        </div>
    </div>
</template>

<style lang="scss">
@import 'app-whitelabel.scss';

    //

    body {
        overflow-y: scroll;
    }

    .uk-container {
        max-width: 1600px;
    }

    .uk-logo {
        width: 126px;
    }

    .a_logo {
        max-height: 38px ;
    }

    .ag_width_limit {
        max-width: 180px;
        margin: 0 auto;
    }

    .la-globe-europe,
    .la-user {
        font-size: 24px;
    }

    body.dark-theme {
        background: #282828;

        & .a_text_shadow {
        text-shadow: 0px 0px 3px #9d9d9d;
        }

        & header {
            border-bottom: 1px solid #353535;
        }

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & .uk-text-lead,
        & .uk-text-emphasis,
        & .la-times {
            color: #fff !important;
        }

        & p,
        & a {
            color: rgba(255, 255, 255, 0.781) !important;
        }

        & .a_svg {
            filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(3355%) hue-rotate(282deg) brightness(109%) contrast(107%);
        }

        & .uk-button-secondary {
            color: #1b1b1b !important;
            background-color: #fff;
        }

        & .uk-button-default,
        & .uk-card-default {
            color: #fff;
            background-color: #1b1b1b;
        }

        & .uk-card-hover:hover {
            background-color: #1b1b1b !important;
        }

        & .section {
            background: #282828;
        }

        & .uk-background-muted,
        & .uk-navbar-dropdown,
        & .uk-modal-body {
            background: #1b1b1b;
        }
        & .uk-card,
        & .uk-card-default,
        & .uk-icon-button {
            background: #1b1b1bb6;
        }
        & .uk-navbar-container {
            background: transparent;
        }
        & .uk-icon-button {
            &:hover{
                background-color: rgba(27, 27, 27, 0.562);
            }
            background: rgba(27, 27, 27, 0.315);
        }

        & .js-upload {
            background: #1b1b1b;
            color: #fff;
        }

    }

    //

    .ag_main_logo {
        position: relative;
    }

    .ag_beta {
        display: block;
        position: absolute;
        top: 49px;
        left: 20px;
        font-size: 11px;
        font-weight: bolder;
        color: #676677;
        background: #c1c0e1;
        padding: 2px 5px;
        line-height: 11px;
        border-radius: 7px;
        box-shadow: 1px 2px 5px #b5858578;
    }

    .a_badge {
        border: 1px solid #1e87f0;
        border-radius: 5px;
        background: #395297;
        color: white;
        padding: 5px 10px;
    }

    header {
        border-bottom: 1px solid #ececec;
    }

    /* The switch - the box around the slider */
    .theme-switcher {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 15px;
    }

    /* Hide default HTML checkbox */
    .theme-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 0px;
        // bottom: 2px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: 0.4s;
        /* box-shadow: 0 0px 15px #2020203d; */
        /* background: white url('/images/sunny.png'); */
        background-image: url('/images/sun.svg');
        background-color: #ffe0ac;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    input:checked+.slider {
        background-color: #464646;
    }

    /* input:focus+.slider {
        box-shadow: 0 0 1px #2196f3;
    } */

    input:checked+.slider:before {
        transform: translateX(12px);
        /* background: white url('/images/night.png'); */
        background-image: url('/images/moon.svg');
        background-color: #797979;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

</style>
